@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
:root {
  /* Colors */
  --dark: #342e59;
  --lightGrey: #c4c4c4;
  --grey: #818181;

  --red: #cc444b;
  --green: #c3d350;
  --lightGreen: #e6f14a;
  --darkGreen: #84a07c;
  --darkGreen1: #5c7457;
  --light: #fffacc;
  --brown: #31231e;
  --lightBlue: #e5eeff;
  --blue: #2258bf;
  --white: #ffffff;
  --inputGrey: #efefef;

  /* Fonts */
  --xl: 20px;
  --l: 16px;
  --m: 14px;
  --s: 11px;
}
html {
  box-sizing: border-box;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

ul {
  list-style: none;
}
a {
  color: var(--dark);
  text-decoration: none;
}
